











































































































































import { ContactClient, ContactUs, ContactUsReason, IContactUs } from "@/api/DoceoApi";
import Vue from "vue";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import InfoDialog from "@/components/userManagement/InfoDialog.vue";

export default Vue.extend({
  name: "Landing",
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },
  mixins: [validationMixin],
  components: { InfoDialog },
  data: () => ({
    isInfoDialogOpen: false,
    infoTitle: "Contact Us",
    infoMessage: "",
    infoButton: "Okay",
    name: "",
    email: "",
    message: "",
    captchaSiteKey: null as string | null,
  }),
  validations: {
    name: { required },
    email: { required, email: (val) => (email as any)(val.toLowerCase()) },
    message: { required },
  },
  created() {
    this.captchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY ?? null;
  },
  mounted() {
    // add captcha script to this component
    const captchaScript = document.createElement("script");
    captchaScript.setAttribute("src", "https://www.google.com/recaptcha/api.js?render=" + this.captchaSiteKey);
    captchaScript.setAttribute("async", "");
    document.getElementById("landingCaptchaScript")?.appendChild(captchaScript);
  },
  methods: {
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const grecaptcha = (window as any).grecaptcha;
      const captchaToken = await new Promise<string>((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha.execute(this.captchaSiteKey, { action: "contact" }).then((token: string) => {
            resolve(token);
          });
        });
      });

      let contactClient = new ContactClient();
      let contactUs: IContactUs = {
        name: this.name,
        email: this.email,
        message: this.message,
        reason: ContactUsReason.BusinessInquiry,
        reCaptchaToken: captchaToken,
      };

      try {
        await contactClient.addContact(new ContactUs(contactUs));
        this.infoMessage = "Thank you for your interested in the Doceo project! We will respond back as soon as possible";
        this.isInfoDialogOpen = true;
        this.name = "";
        this.email = "";
        this.message = "";
        this.$v.$reset();
      } catch {
        this.infoMessage = "Error submitting request";
        this.isInfoDialogOpen = true;
      }
    },
    closeDialog() {},
  },
  computed: {
    nameErrors() {
      const errors = [] as string[];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    messageErrors() {
      const errors = [] as string[];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required && errors.push("Message is required.");
      return errors;
    },
    emailErrors() {
      const errors = [] as string[];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid Email");
      !this.$v.email.required && errors.push("Email is required.");
      return errors;
    },
  },
});
